<template>
  <div class="list__content" ref="list">
    <div class="list__btn-nav-wrap" v-if="isScrollable">
      <button
        @click="scrollToLeft"
        class="list__btn-nav list__btn-nav_left"
        id="list__btn-nav_left"
      >
        <img src="img/icons/list_btn-nav_left.svg" alt="icon" />
      </button>
      <button
        @click="scrollToRight"
        class="list__btn-nav list__btn-nav_right"
        id="list__btn-nav_right"
      >
        <img src="img/icons/list__btn-nav_right.svg" alt="icon" />
      </button>
    </div>
    <div class="btn-configs-wrap list__btn-config">
      <div class="btn-configs working" :class="rightMenu ? 'active' : ''">
        <TablePopUp>
          <template v-slot:activator="{ on }">
            <button v-on="on" class="btn btn-config">
              <img src="img/icons/btn-config_icon-3.svg" alt="icon" />
            </button>
          </template>
          <BasicTable
            ref="table"
            @row_click="openPage($event.id)"
            :header="usedHeaders"
            :table-data="tableData"
          />
        </TablePopUp>
        <HeaderToggler
          :enable-headers="enableHeaders"
          @update:enable-headers="enableHeaders = $event"
        >
          <template v-slot:activator="{ on, init }">
            <button
              @click="init"
              v-on="on"
              class="btn btn-config btn-config_dd"
            >
              <img src="img/icons/btn-config_icon-1.svg" alt="icon" />
            </button>
          </template>
        </HeaderToggler>
        <button
          @click="rightMenu = !rightMenu"
          class="btn btn-config btn-config_activate"
        >
          <img src="img/icons/btn-config_icon-2.svg" alt="icon" />
        </button>
      </div>
    </div>

    <div ref="topScroll" class="top-scroll" @scroll.passive="handleScroll">
      <div
        :style="{
          width: `${scrollWidth}px`,
          height: '12px'
        }"
      />
    </div>
    <div
      ref="content"
      @scroll.passive="handleScroll"
      class="list__content-inner"
      id="list__content-inner"
    >
      <div class="d-flex align-center justify-center" v-if="loading">
        <v-progress-circular indeterminate size="78" />
      </div>
      <BasicTable
        v-else
        ref="table"
        @row_click="openPage($event.id, $event.item, $event.target)"
        @sort_click="sorting($event.value)"
        @all_ckeck_click="checkedAll($event.value)"
        :header="usedHeaders"
        :table-data="tableData"
        :deleteButtonDirectionStart="deleteButtonDirectionStart"
        :deleteAll="deleteAll"
        :restoreButton="restoreButton"
        :disableRouter="disableRouter"
      >
        <template
          v-if="deleteButtonDirectionStart"
          v-slot:delete-td-start="{ itemChild }"
        >
          <td class="btn-delete-wrap"><slot :itemFather="itemChild"></slot></td>
        </template>

        <template v-if="deleteAll" v-slot:delete-all="{ itemChild }">
          <td><slot :itemFather="itemChild"></slot></td>
        </template>

        <template v-if="restoreButton" v-slot:restore-td-child="{ item }">
          <td>
            <slot name="restore-td-father" :itemRestore="item"></slot>
          </td>
        </template>
      </BasicTable>
    </div>

    <div class="v-pagination" v-if="pageLength > 1">
      <v-pagination
        v-model="currentPage"
        color="#95c23d"
        :length="pageLength"
        :total-visible="7"
        @input="goToPage(currentPage)"
      ></v-pagination>
    </div>
    <div v-if="dropDown" class="dropdown-pagination">
      <div class="d-flex justify-end align-center pa-1">
        <p class="dropdown-text">
          Элементов на странице
        </p>
        <v-select
          v-model="dropdownDefault"
          class="dropdown-btn"
          :items="dropdown"
          @input="changedDropdown"
        ></v-select>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderToggler from "@/components/tables/HeaderToggler";
import BasicTable from "@/components/tables/BasicTable";
import TablePopUp from "@/components/tables/TablePopUp";
export default {
  name: "TableLayout",
  components: { TablePopUp, BasicTable, HeaderToggler },
  props: {
    loading: {
      type: Boolean,
      default: () => false
    },
    passlogsUserId: {
      type: Boolean,
      default: () => false
    },
    toRaw: {
      type: String
    },
    passLogsUserId: {
      type: String
    },
    routeObj: {
      type: String
    },
    disableRouter: {
      type: Boolean,
      default: false
    },
    to: {
      type: String
    },
    sourceObject: {
      type: Object,
      required: true
    },
    tableData: {
      type: Array,
      required: true
    },
    header: {
      type: Array || Object,
      required: true
    },
    deleteButtonDirectionStart: {
      type: Boolean,
      default: false
    },
    deleteAll: {
      // удаление чекбоксами
      type: Boolean,
      default: false
    },
    dropDown: {
      // элементов на странице
      type: Boolean,
      default: false
    },
    restoreButton: {
      type: Boolean
    }
  },
  data() {
    return {
      isScrollable: false,
      rightMenu: false,
      scrollWidth: 0,
      scrolling: false,
      enableHeaders: [],
      currentPage: 1,
      page: 1
    };
  },

  computed: {
    dropdown() {
      return this.$store.getters.getDropdown;
    },
    dropdownDefault: {
      get() {
        return this.$store.getters.getDropdownDefault;
      },
      set(value) {
        this.$store.commit("SET_DROPDOWN_DEFAULT", value);
      }
    },
    isDisableRouter() {
      if (this.restoreButton) {
        return true;
      }
      return this.disableRouter;
    },
    usedHeaders() {
      return this.enableHeaders.filter(el => el.enable);
    },
    pageLength() {
      return Math.ceil(this.sourceObject.count / this.dropdownDefault);
    }
  },
  updated() {
    this.scrollWidth = this.$refs.content.scrollWidth + 30;
    this.isScrollable =
      this.$refs.list?.scrollWidth < this.$refs.content?.scrollWidth;
  },
  mounted() {
    this.scrollWidth = this.$refs.content.scrollWidth + 30;
    this.enableHeaders = this.header.map(el => ({ ...el, enable: true }));
    this.currentPage = (() => {
      const offset = this.$route.query.offset;
      return offset ? Math.ceil(offset / this.dropdownDefault) + 1 : 1;
    })();
    this.checkSorting();
  },
  methods: {
    changedDropdown(event) {
      this.currentPage = 1;
      if (event == "Все") {
        this.$store.commit("SET_DROPDOWN_DEFAULT", "Все");
        this.$router.push({
          ...this.$route,
          query: {
            ...this.$route.query,
            offset: 0,
            limit: 10000
          }
        });
      } else {
        this.$router.push({
          ...this.$route,
          query: {
            ...this.$route.query,
            offset: 0,
            limit: event
          }
        });
      }
    },

    sorting(value) {
      this.$store.dispatch("executeSort", value);
    },

    checkedAll(val) {
      if (val.includes("all")) {
        const all = this.tableData.map(el => el.id);
        const existingIds = this.$store.getters.getDeleteList; // массив уже добавленных id на удаления
        return this.$store.commit("UPDATE_DELETE_LIST", [
          ...existingIds,
          ...all
        ]);
      } else {
        return this.$store.commit("UPDATE_DELETE_LIST", []);
      }
    },

    goToPage(page) {
      this.currentPage = page;
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          offset: `${page * this.dropdownDefault - this.dropdownDefault}`
        }
      });
    },
    openPage(id, item, target) {
      // Игнорировать клик, если target содержит указанный класс
      if (target.classList.contains("v-input--selection-controls__ripple")) {
        // TODO: Сделать более универсальное решение
        return;
      }

      if (this.routeObj) {
        const path =
          item.type === "kindergarten"
            ? `/kindergartens/${item.organization_id}`
            : `/objects/${id}`;
        return this.$router.push({ path });
      }

      if (this.toRaw) {
        const path = `/${this.toRaw}/${id}`;
        return this.$router.push({ path });
      }

      const newPageName = this.to ? `${this.to}/` : "";
      const isNotOppenObjectKinder =
        this.$store.getters.getCurrentRole === 60 &&
        this.$route.name === "Objects" &&
        item.type === "kindergarten";

      const fullPath = this.passlogsUserId
        ? `${this.$route.path}/${newPageName}${item.user}`
        : `${this.$route.path}/${newPageName}${id}`;

      if (!this.isDisableRouter && !isNotOppenObjectKinder) {
        return this.$router.push({
          path: fullPath,
          append: true
        });
      }
    },
    scrollToLeft() {
      this.$refs.topScroll.scrollLeft -= 150;
    },
    scrollToRight() {
      this.$refs.topScroll.scrollLeft += 150;
    },
    handleScroll(event) {
      if (event.target._prevClass === "list__content-inner") {
        if (this.scrolling) {
          this.scrolling = false;
          return true;
        }
        this.scrolling = true;
        this.$refs.topScroll.scrollLeft = this.$refs.content.scrollLeft;
      } else {
        if (this.scrolling) {
          this.scrolling = false;
          return true;
        }
        this.scrolling = true;
        this.$refs.content.scrollLeft = this.$refs.topScroll.scrollLeft;
      }
    },
    logout() {
      this.$store.dispatch("logout");
    },
    checkSorting() {
      let ordering = this.$route.query.ordering;
      if (ordering != undefined) {
        if (ordering.charAt(0) == "-") {
          this.$store.commit("SET_TYPE_SORT");
          ordering = ordering.substr(1);
        }
        this.$store.commit("SET_VALUE_SORT", ordering);
      }
      return;
    }
  }
};
</script>

<style lang="scss">
.v-pagination {
  background-color: white;
  height: 50px;
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  -webkit-box-shadow: 0 2px 8px rgb(37 65 106 / 10%);
  box-shadow: 0 2px 8px rgb(37 65 106 / 10%);
  border-radius: 0 0 8px 8px;
  line-height: 15px;
  position: absolute;
  min-width: min-content !important;

  nav {
    background-color: inherit;
    width: 100%;
    height: 100%;
    line-height: inherit;
    box-shadow: none;
  }

  li {
    height: 100%;

    .v-pagination__item,
    .v-pagination__navigation {
      box-shadow: none;
    }

    .v-pagination__more {
      color: black;
    }
  }
}

.top-scroll {
  overflow-x: scroll;
  position: sticky;
  top: -10px;
  z-index: 1000;
}

.dropdown-pagination {
  width: 235px;
  height: 50px;
  position: absolute;
  bottom: -50px;
  right: 0;
  /* border-radius: 0 0 8px 8px; */
  /* box-shadow: 0 2px 8px rgb(37 65 106 / 10%); */
}
.dropdown-btn,
.dropdown-btn .v-select__slot {
  background-color: white;
}

.dropdown-btn {
  width: 52%;
  top: -3px;
}
.dropdown-btn .v-input__slot,
.dropdown-btn .v-select__slot,
.dropdown-btn .v-input__control {
  height: 50px !important;
}

.v-select__selection--comma {
  font-size: 15px;
  line-height: 1.2em;
  font-family: Inter, sans-serif;
  color: #767d92;
}

.dropdown-text {
  font-size: 13px;
  line-height: 1.2em;
  font-family: Inter, sans-serif;
  color: #767d92;
  text-align: right;
  padding-right: 10px;
}

@media screen and (max-width: 650px) {
  .v-pagination {
    position: relative;
    width: 100%;
  }
}
</style>
